

















import { Component, Vue } from "vue-property-decorator";
import store from "@/store/index";
import MediaList from "@/components/MediaList.vue";
import Toolbar from "@/components/Toolbar.vue";
import Hero from "@/components/Hero.vue";
import { Media } from "@/api/models/Media";

@Component({
  components: { MediaList, Toolbar, Hero },
})
export default class Home extends Vue {
  mounted() {
    store.dispatch.media.getMedia();
  }

  get media(): Media[] {
    return store.state.media.media;
  }

  get mediaLoading() {
    return store.state.media.mediaLoading;
  }

  get mediaLoaded() {
    return store.state.media.mediaLoaded;
  }
}
