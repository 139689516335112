import { User } from "@/api";

export const Allowed = (permission: string, user: User | null): boolean => {
  const allowParts = permission.toLowerCase().split(":");

  for (const userPerm in user?.permissions) {
    const userPerm = user?.permissions[0] || "::";
    const userParts = userPerm.toLowerCase().split(":");

    let pass = 0;
    for (let i = 0; i < allowParts.length; i++) {
      if (allowParts[i] === userParts[i] || userParts[i] === "*") {
        pass++;
      }
    }

    if (pass == allowParts.length) {
      return true;
    }
  }

  return false;
};
