import { Media } from "../api/models/Media";
import { deleteData, getData, postData, putData } from "@/helpers/fetch";

export interface AuthState {
  media: Media[];
  mediaLoading: boolean;
  mediaLoaded: boolean;
  typeFilter: string;
  includeDeleted: boolean;
}

interface Action {
  // eslint-disable-next-line
  commit: (mutation: string, data: any) => void;
  state: AuthState;
}

const state = (): AuthState => ({
  media: [],
  mediaLoading: false,
  mediaLoaded: false,
  typeFilter: "All",
  includeDeleted: false,
});

const getMedia = ({ commit, state }: Action) => {
  commit("setMediaLoading", true);
  getData(
    `/media?${state.includeDeleted && "includedeleted=false"}${
      state.typeFilter != "All" && `&type=${state.typeFilter}`
    }`,
    false,
    ({ data }) => {
      commit("setMedia", data.data);
    }
  );
};

// getters
const getters = {};

// actions
const actions = {
  async getMedia(action: Action): Promise<void> {
    getMedia(action);
  },
  async watchMedia(action: Action, media: Media): Promise<void> {
    media.watched = true;
    putData(`/api/media/${media.id}`, media, true, () => {
      getMedia(action);
    });
  },
  async unwatchMedia(action: Action, media: Media): Promise<void> {
    media.watched = false;
    putData(`/api/media/${media.id}`, media, true, () => {
      getMedia(action);
    });
  },
  async createMedia(action: Action, media: Media): Promise<void> {
    media.watched = true;
    postData("/api/media", media, true, () => {
      getMedia(action);
    });
  },
  async updateMedia(action: Action, media: Media): Promise<void> {
    putData(`/api/media/${media.id}`, media, true, () => {
      getMedia(action);
    });
  },
  async setFilter(action: Action, typ: string): Promise<void> {
    if (typ !== action.state.typeFilter) {
      action.commit("setFilter", typ);
      getMedia(action);
    }
  },
  async setIncludeDeleted(action: Action, del: boolean): Promise<void> {
    if (del !== action.state.includeDeleted) {
      action.commit("setIncludeDeleted", del);
      getMedia(action);
    }
  },
};

// mutations
const mutations = {
  setMedia(state: AuthState, media: Media[]): void {
    state.media = media;
    state.mediaLoading = false;
    state.mediaLoaded = true;
  },
  setMediaLoading(state: AuthState, mediaLoading: boolean): void {
    state.mediaLoading = mediaLoading;
  },
  setMediaLoaded(state: AuthState, mediaLoaded: boolean): void {
    state.mediaLoaded = mediaLoaded;
  },
  setFilter(state: AuthState, typ: string): void {
    state.typeFilter = typ;
  },
  setIncludeDeleted(state: AuthState, del: boolean): void {
    state.includeDeleted = del;
  },
};

export default {
  // eslint-disable-next-line
  namespaced: true as true,
  state,
  getters,
  actions,
  mutations,
};
