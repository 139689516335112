




























































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Media } from "@/api/models/Media";
import store from "@/store";
import { Allowed } from "@/helpers/auth";

@Component
export default class MediaList extends Vue {
  @Prop() private media!: Media;
  @Prop({ default: "div" }) private tag!: string;

  onDelete(): void {
    if (this.media.deleted != null) {
      this.media.deleted = !this.media.deleted;
      store.dispatch.media.updateMedia(this.media);
    }
  }

  toggleWatched(): void {
    if (!this.media.watched) {
      store.dispatch.media.watchMedia(this.media);
    } else {
      store.dispatch.media.unwatchMedia(this.media);
    }
  }

  priorityUp(): void {
    if (this.media.priority != null) {
      this.media.priority++;
      store.dispatch.media.updateMedia(this.media);
    }
  }

  priorityDown(): void {
    if (this.media.priority != null) {
      this.media.priority--;
      store.dispatch.media.updateMedia(this.media);
    }
  }

  imdbLink(name: string): string {
    return `https://www.imdb.com/find?q=${name.replaceAll(" ", "+")}`;
  }

  get editAllowed(): boolean {
    return Allowed("media:update:*", store.state.auth.user);
  }

  get deleteAllowed(): boolean {
    return Allowed("media:delete:*", store.state.auth.user);
  }

  get priorityType(): string {
    if (this.media.deleted) {
      return "is-danger";
    }

    if (!this.media.priority) {
      return "is-info";
    }

    if (this.media.priority > 0) {
      return "is-success";
    } else if (this.media.priority < 0) {
      return "is-warning";
    } else {
      return "is-info";
    }
  }
}
