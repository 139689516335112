export interface Callback {
  data: any;
  code: number;
}

export type CallbackFunc = (resp: Callback) => void;

const init = {
  mode: "cors",
  cache: "no-cache",
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json",
  },
  redirect: "follow",
  referrerPolicy: "no-referrer",
} as RequestInit;

export const postData = (
  url: string,
  data: any,
  redirectToLogin: boolean,
  callback: CallbackFunc
) => request("POST", url, data, redirectToLogin, callback);

export const putData = (
  url: string,
  data: any,
  redirectToLogin: boolean,
  callback: CallbackFunc
) => request("PUT", url, data, redirectToLogin, callback);

export const getData = (
  url: string,
  redirectToLogin: boolean,
  callback: CallbackFunc
) => request("GET", url, null, redirectToLogin, callback);

export const deleteData = (
  url: string,
  redirectToLogin: boolean,
  callback: CallbackFunc
) => request("DELETE", url, null, redirectToLogin, callback);

const request = (
  method: string,
  url: string,
  data: any | null,
  redirectToLogin: boolean,
  callback: CallbackFunc
) => {
  fetch(url, {
    ...init,
    method,
    body: data ? JSON.stringify(data) : null,
  }).then((response) => {
    if (redirectToLogin && response.status === 401) {
      window.location.href = "/auth/login";
    } else if (response.status != 204) {
      response
        .json()
        .then((data) => callback({ data: data, code: response.status }));
    } else {
      callback({ data: null, code: response.status });
    }
  });
};
