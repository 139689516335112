














import { Component, Prop, Vue } from "vue-property-decorator";
import { Media } from "@/api/models/Media";
import MediaCard from "@/components/MediaCard.vue";

@Component({ components: { MediaCard } })
export default class MediaList extends Vue {
  @Prop() private media!: Media[];
  @Prop() private columns!: number;

  get mediaColumns(): Media[][] {
    var chunkSize = Math.ceil(this.media.length / this.columns);
    if (chunkSize <= 0) throw "Invalid chunk size";
    var ret = [];
    for (var i = 0, len = this.media.length; i < len; i += chunkSize)
      ret.push(this.media.slice(i, i + chunkSize));
    return ret;
  }
}
