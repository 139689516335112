



































import { Component, Prop, Vue } from "vue-property-decorator";
import store from "@/store/index";

@Component
export default class Navbar extends Vue {
  mounted() {
    store.dispatch.auth.getUser();
  }

  get authed() {
    return store.state.auth.authed;
  }

  get user() {
    return store.state.auth.user;
  }
}
