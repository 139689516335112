











import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Hero extends Vue {
  @Prop() private title!: string;
  @Prop() private subtitle!: string;
  @Prop() private type!: string;

  get typeClass(): string {
    return this.type || "is-primary";
  }
}
