



















































































import { Component, Vue } from "vue-property-decorator";
import { Media } from "@/api/models/Media";
import store from "@/store";
import { Allowed } from "@/helpers/auth";

@Component
export default class MediaList extends Vue {
  private media: Media = {};

  onCreate(): void {
    if (this.media.id) {
      store.dispatch.media.createMedia(this.media);
    }
  }

  open(type: string): void {
    this.media.type = type;
    this.$buefy.dialog.prompt({
      message: `${this.media.type} name`,
      inputAttrs: {
        placeholder: "Name",
      },
      trapFocus: true,
      scroll: "keep",
      type: "is-info",
      onConfirm: (value) => {
        this.media.name = value;
        this.media.type = type;
        store.dispatch.media.createMedia(this.media);
      },
      onCancel: () => (this.media = {}),
    });
  }

  get createAllowed(): boolean {
    return Allowed("media:create:*", store.state.auth.user);
  }

  get typeFilter(): string {
    return store.state.media.typeFilter;
  }

  set typeFilter(val: string) {
    store.dispatch.media.setFilter(val);
  }

  get includeDeleted(): string[] {
    return store.state.media.includeDeleted ? ["IncludeDeleted"] : [];
  }

  set includeDeleted(val: string[]) {
    store.dispatch.media.setIncludeDeleted(val.includes("IncludeDeleted"));
  }

  get authed(): boolean {
    return store.state.auth.authed;
  }

  get allowedDelete(): boolean {
    return Allowed("media:delete:*", store.state.auth.user);
  }

  get allowedCreate(): boolean {
    return Allowed("media:create:*", store.state.auth.user);
  }
}
