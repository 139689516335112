import { User } from "../api/models/User";
import { getData } from "@/helpers/fetch";
import { UserResponse, UsersApi } from "@/api";

export interface AuthState {
  user: User | null;
  userLoading: boolean;
  userLoaded: boolean;
  authed: boolean;
}

interface Action {
  commit: (mutation: string, data: any) => void;
  state: AuthState;
}

const state = (): AuthState => ({
  user: null,
  userLoading: false,
  userLoaded: false,
  authed: false,
});

// getters
const getters = {};

// actions
const actions = {
  async getUser({ commit }: Action): Promise<void> {
    commit("setUserLoading", true);
    getData("/api/me", false, ({ data, code }) => {
      if (code == 401) {
        commit("setUser", { user: null, authed: false });
        return;
      }
      commit("setUser", { user: data.data, authed: true });
    });
  },
};

// mutations
const mutations = {
  setUser(
    state: AuthState,
    { user, authed }: { user: User; authed: boolean }
  ): void {
    state.user = user;
    state.userLoading = false;
    state.userLoaded = true;
    state.authed = authed;
  },

  setUserLoading(state: AuthState, userLoading: boolean): void {
    state.userLoading = userLoading;
  },

  setUserLoaded(state: AuthState, userLoaded: boolean): void {
    state.userLoaded = userLoaded;
  },
};

export default {
  // eslint-disable-next-line
  namespaced: true as true,
  state,
  getters,
  actions,
  mutations,
};
